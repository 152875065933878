@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  box-shadow: #d2cef6;;
  font-family: Trebuchet MS, sans-serif	;
  /* Comic Sans MS, Comic Sans, cursive	 */
  /* Trebuchet MS, sans-serif		 */
}
:root {
  --first-color: #342b79;
  --opacity-first: #3c3191;
  --second-color: #1d182c;
  --opacity-second: #f27c2c;
  --text-color: #ffffff;
  --dark-color: #0c0a2b;
  --light-color: #d2cef6;
  --bg-color: #e8e5fb;
  --body-bg: #ffffff;
  --value: attr(data-value);
}
.app-container {
  width: 100%;
  padding: 3rem 2rem ;
  margin: auto;
  height: 100%;
  background-image: url("../../assets/Game bg .png");
  background-repeat: no-repeat;
  background-size: cover; 
}

.error-link {
  width: 100%; /* Adjust width for better responsiveness on smaller screens */
  margin: 2rem auto 0 auto;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  color: var(--text-color);
  text-decoration: none;
  background-color: var(--second-color);
}

@media screen and (max-width: 768px) {
  .app-container {
    padding: 0px ;
    margin: auto;
    height: 100%;
    width: 100%;
  }

.error-link {
  width: auto; 
}
}

