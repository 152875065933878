.menu-icon {
  cursor: pointer;
  font-size: 25px;
  margin: 10px;
  color: aliceblue;
}

.intro-box {
  position: relative;
  display: grid;
  grid-template-columns: calc(100% - 70px) 70px;
  gap: 0.5rem;
  align-items: center;
  background-color: var(--first-color);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  padding-top: 80px;
  overflow: hidden;
}

.intro-box::after {
  content: "";
  position: absolute;
  left: -25px;
  top: -25px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--opacity-first);
  z-index: 0;
}
.intro-texts {
  z-index: 1;
  margin-top: -90px;
  /* margin-bottom: 10px; */
}
.intro-title {
  font-size: 2rem;
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  color: var(--light-color);
  /* font-weight: 400; */
  margin-bottom: 0.5rem;
  z-index: 3;
}
.intro-description {
  /* font-size: 0.95rem; */
  color: var(--text-color);
}
.intro-icon {
  width: 100px;
  height: 100px;
  margin-left: -20px;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
  background-color: var(--opacity-first);
  z-index: 1;
  margin-top: -40px;
}

.intro-icon i {
  font-size: 1.25rem;
  color: var(--text-color);
}

.earn-more-coins-button {
  background-color: #f27c2c;
  color: white; 
  padding: 12px 16px;  
  font-size: 12px;  
  border: none; 
  border-radius: 50px; 
  cursor: pointer; 
}
.earn-more-coins-button:hover {
  background-color: #e66700; 
}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  /* .intro-box {
    grid-template-columns: 1fr; 
    text-align: center;
    padding: 1.5rem; 
    margin-top: 50px;
  } */

  .intro-box::before,
  .intro-box::after {
    display: none; /* Hide pseudo-elements on smaller screens */
  }

  .level-box {
    max-width: 100%; 
  }
  .intro-icon {
      width: 80px; 
      height: 80px; 
      margin-left: 0; 
    }
}


.quizList_quizCard {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--first-color);
  border: 0 solid #4c51af;
  border-radius: 1rem;
  margin-bottom: 12px;
  width: 100%;
  min-height: 146px;
  /* background: #1f237e; */
}
.quizList_cardContent {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  padding: 12px;
}

.quizList_thumbnialImg {
  display: flex;
  flex-wrap: wrap;
  width: 76px;
  background: #fff;
  border-radius: 16px;
  height: 76px;
  justify-content: center;
  align-items: center;
  min-width: 76px;
  
}

.quizList_quizNameStatus {
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
  align-content: flex-start;
}

.quizList_categoryLiveDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  text-transform: uppercase;
}

.quizList_categories {
  font-size: 10px;
  font-weight: 600;
  color: #dfe1fc;
}

/* .quizList_statusLive {
  color: #55e6c1;
  font-size: 10px;
  display: flex;
  position: relative;
  font-weight: 700;
} */
@keyframes liveAnimation {
  0% {
    transform: scale(1.5);
    color: #55e6c1;
  }
  50% {
    transform: scale(1.1);
    color: #ff8c00; 
  }
  100% {
    transform: scale(1.5);
    color: #55e6c1;
  }
}

.quizList_statusLive {
  color: #55e6c1;
  font-size: 10px;
  display: inline-flex; 
  align-items: center; 
  font-weight: 700;
}

.liveCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #55e6c1; 
  margin-right: 5px;
  animation: liveAnimation 1.5s infinite; 
}


.quizList_cardFooter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #4c51af8c;
  font-size: 12px;
}

.quizList_spacer {
  margin-right: 4px;
}

/* Default styles for the button */
.quizList_btn {
  background: #f27c2c;
  padding: 16px;
  height: 56px;
  width: 100%;
  line-height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  position: relative;
  overflow: hidden;
  border: none;
  transition:  0.3s; /* Add a smooth transition for background changes */
}

/* Styles for the button on hover */
.quizList_btn:hover {
  background: #e66700; /* Change the background color on hover */
}
/* Styles for the button on click */
.quizList_btn:active {
  background: #d35400; /* Change the background color on click */
}

/* Additional styles for the small button */
.quizList_btnSmall {
  height: 34px;
  width: 96px;
  font-size: 16px;
  padding: 8px;
  font-weight: 600;
  text-shadow: 0 1px 0 #f27c2c;
  line-height: 20px;
  text-transform: uppercase;
  float: right;
  border-radius: 50px;
  margin-left: 0;
}
/* Additional styles for the small button on hover */
.quizList_btnSmall:hover {
  background: #e66700; /* Change the background color on hover */
}

/* Additional styles for the small button on click */
.quizList_btnSmall:active {
  background: #d35400; /* Change the background color on click */
}
/* Media query for mobile view */
@media only screen and (max-width: 600px) {
  .quizList_btn {
    width: auto; 
    margin-top: 10px; 
    font-size: 14px;
    width: 60px;
  }
  .quizList_btnSmall {
    float: none; 
    margin-left: auto; 
    margin-top: 10px; 
    margin-bottom: 10px;
    font-size: 14px;
    width: 60px;
  }
}


.trending-articles {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--first-color);
    border: 0 solid #4c51af;
    border-radius: 1rem;
    margin-bottom: 12px;
    width: 100%;
    min-height: 166px ;
    margin-top: 20px;
}

.section-title {
    display: flex;
    flex-wrap: nowrap;
    padding: 1px;
    font-size: 20px;
    /* margin-bottom: 15px; */
    color: #fff;
    margin-left: 15px;
    margin-top: 20px;
}

.article-content {
  display: flex;
  align-items: center;
  padding-top: 15px;
  border-bottom: 1px solid #192254;
  transition: border-color 0.3s; /* Add a smooth transition effect for better user experience */
}

.article-content:hover {
  border-color: #4c51af8c; 
  background-color: #080456; 
}

.article-image {
    display: flex;
    flex-wrap: wrap;
    width: 76px;
    background: #fff;
    border-radius: 16px;
    height: 76px;
    justify-content: center;
    align-items: center;
    min-width: 76px;
    object-fit: cover;
    margin-left: 15px;
    margin-bottom: 15px;
}

.article-text {
  flex: 1;
  margin-bottom: 15px;
  text-decoration: none;
}

.article-title {
  display: flex;
    flex-wrap: wrap;
    padding-left: 12px;
    align-content: flex-start;
    color: #fff;
    margin-bottom: 9px;
}

.article-description {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    align-content: flex-start;
    font-size: 12px;
    color: var(--light-color);; 
    padding-right: 15px;
    justify-content:space-around;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
}
