.quiz-box {
  max-width: 200%;
  height:100vh;
  width: 440px;
  margin: auto;
  padding: 0rem 2rem;
  background-color: #192254;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width:auto;
  
  &::-webkit-scrollbar {
    width: 0px;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #97a0d3;
    border-radius: 4px;
    border: 1px solid #364180;
  }

  &::-webkit-scrollbar-track {
    background-color: #364180;
    border-radius: 0px;
    display: none;
  }
}
.progress-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--first-color);
  border-radius: 1rem;
  padding: 1.5rem 2rem 0 2rem;
  margin-top: 35px;
}
.progress-top {
  display: grid;
  grid-template-columns: calc(100% - 70px) 70px;
  gap: 0.5rem;
  align-items: center;
}
.progress-title {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--light-color);
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.progress-description {
  font-size: 0.65rem;
  color: var(--text-color);
}
.progress-icon {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
  background-color: var(--opacity-first);
}
.progress-icon i {
  font-size: 1.25rem;
  color: var(--text-color);
}
.progress-bottom {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 125px calc(100% - 125px);
  align-items: center;
  gap: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: var(--second-color);
  padding: 1.5rem;
  overflow: hidden;
}
.progress-bottom::before {
  content: "";
  position: absolute;
  left: 40%;
  bottom: -25px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 7px solid var(--opacity-second);
  z-index: 0;
}
.progress-bottom::after {
  content: "";
  position: absolute;
  left: 85%;
  top: -25px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--opacity-second);
  z-index: 0;
}
.progress-circle {
  --percentage: var(--value);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
      closest-side,
      var(--second-color) 80%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(
      var(--body-bg) calc(var(--percentage) * 1%),
      var(--opacity-second) 0
    );
  z-index: 1;
}
.progress-big {
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--text-color);
}
.progress-mini {
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 0.75rem;
}
.progress-detail {
  font-size: 0.85rem;
  font-weight: 500;
  color: var(--text-color);
  z-index: 1;
}
.progress-circle.time {
  --percentage: var(--value);
  width: 70px;
  height: 70px;
  background: radial-gradient(
      closest-side,
      var(--bg-color) 85%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(
      var(--first-color) calc(var(--percentage) * 1%),
      var(--body-bg) 0
    ) !important;
}
.question-box {
  position: relative;
  width: 100%;
  margin: 1.5rem 0 0 0;
  display: grid;
  grid-template-columns: calc(100% - 70px) 70px;
  align-items: center;
  /* gap: 0.5rem; */
}
.question-title {
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #F5F5F5;
  margin-bottom: 0.5rem;
}
.question {
  font-size: 1rem;
  font-weight: 600;
  color: #d9dbe6;
}
.time {
  font-size: 1.25rem;
  font-weight: 800;
}
.answers-boxes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 2rem;
}
.answer-label {
  width: 100%;
  background-color: var(--body-bg);
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.95rem;
  border: 1px solid transparent;
  color: var(--dark-color);
  text-transform: capitalize;
  transition: all 0.25s;
}

.answer-label:active {
  transform: scale(0.9);
}
.answer-label.selected {
  background-color: red;
  color: #dfe0e8;
}

/* 
.answer-label.correct {
  background-color: green;
}
.answer-label.incorrect {
  background-color: red;
}
.answer-label.selected {
  border: 2px solid #333;
  color: white;
  background-color: #007bff;
} */


.answer-label input {
  outline: none;
  border: none;
  accent-color: var(--text-color);
}
.message {
  width: 300px;
  margin: 2rem auto 0 auto;
  display: flex;
  gap: 1.5rem;
  flex-wrap: nowrap;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  background-color: #CD6155;
}
.message.animation {
  animation: message 1.5s ease-in-out infinite;
}
.message .icon {
  width: 40px;
  height: 40px;
  border-radius: 0.25rem;
  display: grid;
  place-items: center;
  background-color: #E6B0AA;
}
.message .icon i {
  font-size: 1.25rem;
  /* color: var(--text-color); */
}
.message span {
  font-size: 1rem;
}
@keyframes message {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
.next {
  position: relative;
  width: 100%;
  display: flex;
  margin: 1.5rem 0 0 0;
}

.score-box {
  margin-top: 30px;
  text-align: center;
}

.score-title {
  font-size: 20px;
  color: #e0ebf3;
}


@media screen and (max-width: 768px) {
  .quiz-box {
    height: 110vh;
    padding: 3rem 2rem;
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 600px) {
  .progress-box {
    /* padding-bottom: 20px; */
    width: 100%;
  }
  .progress-circle {
    width: 80px;
    height: 80px;
  }
  .progress-detail {
    font-size: 0.65rem;
    
  }
  .progress-description {
    font-size: 0.60rem;
  }
  .progress-bottom {
    gap: 0rem;
    text-align: center;
  }
  .progress-bottom::after {
    left: 75%;
  }
  .answers-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}

