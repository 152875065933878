.playNow_playNow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    position: relative;
    border-radius: 16px;
    background: var(--first-color);
    /* margin-top: 16px; */
    padding: 16px 30px 20px;
    min-height: 200px;
    margin-top: 30px;
}
.playNow_stage {
    display: flex;
    width: 100%;
}
.playNow_bounce {
    animation-name: playNow_bounce;
    animation-timing-function: ease;
    text-align: center;
}
.playNow_box {
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: 1;
    margin: 0 auto;
    transform-origin: bottom;
}
.lottie_lottieCoins {
    width: 130px;
    height: 120px;
}
.playNow_playNow h1 {
    font-size: 22px;
    font-weight: 700;
    margin-top: 15px;
}
.playNow_playNow p {
    text-align: center;
    margin-bottom: 12px;
    margin-top: 10px;
    font-size: 15px;
    color: #b6b3ff;
    line-height: 22px;
}
.playNow_animated {
    /* animation-duration: 1s; */
    animation-duration: var(--animate-duration);
    animation-fill-mode: both;
}
.playNow_shine {
    position: relative;
    overflow: hidden;
}
.playNow_btn {
    background: #f27c2c;
    padding: 0 16px;
    height: 56px;
    width: 100%;
    display: inline-block;
    justify-content: center;
    border-radius: 50px;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin-bottom: -1px;
    margin-top: 10px;
    line-height: 56px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    border: none;
    transition:  0.3s ease; /* Adding a smooth transition for the background color change */
}

.playNow_btn:hover {
    background: #d35400; /* Change the background color when hovered */
}


@media screen and (max-width: 768px) {
    .playNow_playNow {
        padding: 16px 10px 25px;
        min-height: auto;
        margin-top: 40px;
    }

    .playNow_btn {
        padding: 0 8px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        margin-bottom: -10px;
        margin-top: 10px;
        text-decoration: none;
    }
}
/* 
@media screen and (max-width: 600px) {
    .playNow_playNow {
        padding: 16px 10px 25px;
        min-height: auto;
        margin-top: 40px;
    }

    .playNow_btn {
        padding: 0 8px;
        height: 40px;
        font-size: 16px;
        line-height: 40px;
        margin-bottom: -10px;
        margin-top: 10px;
        text-decoration: none;
    }
} */
