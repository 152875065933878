.blog-page {
    max-width: 800px;
    margin: 0px -30px;
    padding: 20px;
  }
  .blog-content {
    color: var(--light-color);
    line-height: 1.6;
    text-align:justify; 
  }
  
  .p {
    margin-bottom: 20px;
    font-size: 15px;
  }
  