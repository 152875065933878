.page_container {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 auto;
    min-height: 100vh;
    position: relative;
    z-index: 2;
    height: auto;
  }
  .page_ad {
    /* min-width: 250px;
    margin-bottom: 30px;
    border-radius: 0;
    padding: 15px 16px 16px;
    background: transparent;
    position: relative;
    min-height: 300px; */

    width: 100%;
    margin-bottom: 16px;
    border-radius: 0;
    padding: 15px 16px 16px;
    background: transparent;
    position: relative;
    height: auto;
    min-height: 321px;
  }
  .page_sponsored {
    display: block;
    text-align: center;
    max-width:  100%;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.4;
    text-transform: uppercase;
    color: #e0e0e0;
    padding-top: 10px;
  }
  
  /* .quizCard {
    padding: 1px;
    margin-bottom: 10px;
    padding-left: 1px;
    padding-right: px;
  } */
  
  .quizCard_header {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;
    color: #e0e0e0;
}
  .quizCard_header h1 {
    font-size: 22px;
}
  .quizCard_header p {
    color: hsla(0,0%,100%,.64);
    line-height: 20px;
}
  
  .question {
    /* border: 1px solid #daafaf; */
    /* padding: 10px; */
    margin-bottom: 10px;
  }
  
  .quizCard_qaOptions {
    /* margin-top: 10px; */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        background: #464a92;
        border-radius: 16px;
        padding: 24px 8px;
        min-height: 240px;
        background-size: contain;
  }

  .questionHeader_questionHeading {
    width: auto;
    height: 40px;
    margin-top: -44px;
    border-radius: 50px;
    color: #fff;
  }

  .questionHeader_inner {
    width: 100%;
    height: 100%;
    border-radius: 50px;
    border: 4px solid #0e0a5f;
    background: #1f237e;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 16px;
  }
  .questionHeader {
    font-size: 14px;
    display: flex;
    line-height: 12px;
    padding-left: 8px;
  }
  
   .quizCard_qaOptions h3 {
    padding: 4px 0 12px;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    width: calc(100% - 16px);
    min-height: 58px;
    color: #f0f0f0;
}
.quizCard_qaOptions ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
}
.quizCard_qaOptions ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47%;
  margin-bottom: 12px;
  border-radius: 8px;
  text-align: center;
  position: relative;
}
.quizCard_qaOptions ul li button {
  width: 100%;
  min-height: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 14px;
  color: #282d93;
  font-weight: 600;
  box-shadow: 0 1px 3px #2a2f98;
  border: 0 solid #272c93;
  border-radius: 8px;
  cursor: pointer;
  background: #fff;
}

  .contentList_contentList {
    padding: 16px 0 0;
    color: #fff;
}
.contentList_contentList h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 32px;
    position: relative;
    text-align: center;
}
.contentList_contentList h2:before {
    content: "";
    height: 3px;
    background: #ff7e26;
    width: 150px;
    position: absolute;
    top: 42px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.contentList_contentList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.contentList_contentList ul li {
    line-height: 1.4;
    padding-bottom: 24px;
    padding-left: 40px;
    position: relative;
    font-size: 15px;
    font-weight: 400;
    color: #a8a5ff;
}
.contentList_contentListul li:before {
    content: "";
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.contentList_contentList ul li:after {
    content: "";
    display: flex;
    position: absolute;
    top: 3px;
    left: 7px;
    width: 5px;
    height: 14px;
    border: solid #a8a5ff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Styles for selected answer buttons */
.quizCard_qaOptions ul li button.selected {
  background-color: #f0f0f0; /* Default background color for selected buttons */
}

/* Styles for correct answers */
.quizCard_qaOptions ul li button.correct {
  background-color: #4caf50; /* Green background color for correct answers */
  color: #e0e0e0;
}

/* Styles for incorrect answers */
.quizCard_qaOptions ul li button.incorrect {
  background-color: #ff4d4d; /* Red background color for incorrect answers */
  color: #e0e0e0;

}

/* Styles for feedback messages */
.feedback.correct {
  color: #4caf50; /* Green color for correct feedback */
}

.feedback.incorrect {
  color: #ff4d4d; /* Red color for incorrect feedback */
}

@media only screen and (max-width: 768px) {
  .page_container {
    width: 100%;
    padding: 0 10px; 
  }
  .page_ad {
    padding-top: 20px; 
  }
  .page_sponsored {
    font-size: 15px; 
  }
  .quizCard_qaOptions {
    padding: 16px 9px 5px 8px;
    min-height: 180px;
}
}
