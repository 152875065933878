.header_header__R3wYf {
    width: calc(100% + 60px);
    /* width: 200%; */
    height: 60px;
    padding: 12px 20px 12px 18px;
    background-color: var(--first-color);
    box-shadow: 0 1px 1px 0 hsla(0,0%,100%,.19);
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    top:0;
    z-index: 2;
    color: #fff;
    margin: auto -20px 40px -30px;
    display: flex;
    justify-content: space-between ;
    
  }
  .header_logo__4Zn2n {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    flex-wrap: nowrap;
    width: 50%;
    font-weight: 700;
    font-size: 22px;
    align-items: center;
  }
  .header_logo__4Zn2n nav {
    display: flex;
    flex-wrap: wrap;
    margin-right: 8px;
    cursor: pointer;
  }
  .header_header__R3wYf label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .header_logo__4Zn2n nav img {
    cursor: pointer;
  }
  .header_headerRight__beEHK {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .header_totalCoins__4w69L {
    width: auto;
    min-width: 80px;
    padding: 1px 16px 4px 8px;
    height: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-wrap: nowrap;
    background: #080456;
    border-radius: 24px;
    /* animation: rotateAnimation 2s linear infinite; */
  }
  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .header_totalCoins__4w69L a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .header_totalCoins__4w69L a span {
    line-height: 15px;
    padding-left: 10px;
    padding-top: 4px;
    font-size: 15px;
    font-weight: 900;
    line-height: 12px;
  }
  .header_totalCoins__4w69L a span span {
    width: 100%;
    display: flex;
    font-weight: 400;
    padding: 0;
    font-size: 10px;
  }
  .header_notification__roink {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: end;
    background: #0e0a5f;
    border-radius: 28px;
    padding: 8px;
    margin-left: 1px;
    margin-right: -5px;
    position: relative;
    cursor: pointer;
    animation: rotate 0.2s linear infinite; /* Adjust duration and timing function as needed */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

  /* Media query for screens with a maximum width of 768px (typical mobile devices) */
@media (max-width: 768px) {
  .header_sidebarToggle__Z8XaD{
    flex-direction: row;
    align-items: center;
  }
  .header_header__R3wYf {
      padding: 12px;
  }
  .header_logo__4Zn2n {
      font-size: 16px; 
  }
  .header_headerRight__beEHK {
      flex-direction: row;
      align-items: center;
  }
  .header_logo__4Zn2n nav img {
    cursor: pointer;
    height: 30px;
    width: auto;

  }
}