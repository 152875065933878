@import url("https://fonts.googleapis.com/css2?family=Nabla&display=swap");

.result-box {
  width: 100%;
  background-color: var(--second-color);
  padding: 1.9rem;
  border-radius: 1rem;
  margin-bottom: 3rem;
}

.result-bg {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 120px;
  display: grid;
  place-items: center;
}

.result-bg img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  opacity: 0.6;
}

.percentile {
  position: relative;
  top: -10px;
  font-size: 5.5rem;
  z-index: 9;
  font-family: "Nabla", cursive;
}

.result-detail {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-color);
  font-weight: 500;
  margin: 1rem 0;
  animation: fadeIn 1s ease;
}
.positive-score {
  color: #28a745; /* Green color for positive scores */
}

.negative-score {
  color: #dc3545; /* Red color for negative scores */
}

.timeOver {
  font-size: 17px;
  margin-top: 5px;
  color: #fafcfd;
  text-align: center;
}

.new-quiz {
  display: inline-block;
  text-decoration: none;
  width: 100%;
  text-align: center;
  color: var(--text-color);
  background-color: var(--opacity-second);
  border-radius: 0.75rem;
  font-size: 1rem;
  padding: 1.25rem 1.5rem;
  cursor: pointer;
  outline: none;
  border: none;
  margin-top: 20px;
}

.new-quiz:hover {
  background-color: #a95913; /* Darker blue color on hover, adjust as needed */
}

.info_infoOver {
  display: flex;
  flex-direction: column; /* Adjust for smaller screens */
  align-items: center;
  text-align: center;
  margin: 20px 10px;
}

.info_box {
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.64);
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 10px;
}

.info_infoOver h2 {
  font-size: 22px;
  line-height: 22px;
  color: white;
  display: flex;
  justify-items: center;
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rank_smListCard {
  background-color: #464a92;
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
}

.rank_smListCard ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* Adjust for smaller screens */
  justify-content: space-around;
}

.rank_smListCard li {
  text-align: center;
  flex: 1; 
  margin: 8px; 
  /* border-right: 1px solid #192254; */
}

.rank_smListCard h2 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #fafcfd;
}

.rank_smListCard p {
  font-size: 1rem;
  color: #dae8f4;
}

@media screen and (max-width: 768px) {
  .info_infoOver {
    margin: 20px 10px; /* Further adjusted margin for smaller screens */
  }
  /* .info_content {
    margin-top: 16px;
  } */
}








