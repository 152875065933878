.playNows_playNows {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #e9e9ee;
    position: relative;
    border-radius: 16px;
    background: var(--first-color);
    margin-top: 15px;
    padding: 30px 30px 20px;
    min-height: 200px;
}
.headrow {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
}
.quizhead {
    color: #fff;
    font-size: 1.5rem; 
    font-weight: 600;
    text-align: left;
    margin-left: 4px;
    text-decoration: none;
    margin-bottom: 0.5rem; 
    /* padding-top: 0.1rem;  */
}

.headrow:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-w-14 {
    width: 0.875em;
}
.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}

.playNows_title {
    font-size: 15px;
    margin-bottom: 8px;
    /* margin-top: 40px; */
    font-weight: 400;
}

.playNows_playNows h3 {
    font-size: 20px;
    text-align: center;
    color:white;
    margin-top: 30px;
    margin-left: 5px;
    display: flex;
    justify-items: center;
    animation: fadeIn 1s ease;
    /* font-family: Gill Sans, sans-serif; */
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.playNows_playNows p {
    text-align: center;
    /* margin-bottom: 12px; */
    margin-top: 10px;
    padding-top: 20px;
    /* font-size: 15px; */
    color: #b6b3ff;
    line-height: 19px;
}

@media screen and (max-width: 600px) {
    /* .playNows_playNows {
      padding: 20px;
      margin-top: 1px;
    } */
    /* .playNows_playNows h3 {
      font-size: 18px;
    } */
    .playNows_playNows p {
        margin-top: 0px;
        padding-top: 10px;
    }
    
}
@media screen and (max-width: 600px) {
    .headrow {
        padding: 3px 10px; 
        margin-left: -10px;
        margin-top: 1px;
        /* margin-bottom: 5px; */
    }
    .quizhead {
        font-size: 1.2rem; 
        margin-bottom: 0.3rem; 
        padding-top: 2rem; 
    }
}


.playNow_thumbnialImg {
    width: 120px;
    height: 120px;
    background: #fff;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px auto;
}

.playNow_bounce__ycV8u {
    animation-name: playNow_bounce__ycV8u;
    animation-timing-function: ease;
    text-align: center;
}

.playNow_box___ijuT {
    align-self: flex-end;
    animation-duration: 2s;
    animation-iteration-count: 1;
    margin: 0 auto;
    transform-origin: bottom;
}